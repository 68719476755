<template>
  <v-container fluid>
    <!-- Tarjeta de certificado de membresía -->
    <v-row class="text-center ma-3" justify="center">
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7" class="pa-0">
        <v-card
          elevation="2"
          class="d-flex flex-column"
          height="100%"
          :rounded="$vuetify.breakpoint.mdAndDown ? 't-xl b-0' : 'l-xl r-0'"
        >
          <v-img
            position="center"
            class="ma-0 d-flex"
            content-class="filter-red"
            src="@/assets/images/landing/memberLanding/background.png"
          >
            <v-card
              height="100%"
              color="transparent"
              :class="
                $vuetify.breakpoint.mdAndDown
                  ? 'd-flex flex-column justify-center'
                  : 'd-flex flex-column pa-5'
              "
            >
              <v-card-title
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-left'
                "
                class="wDarkerBlue--text ma-0"
                ><p
                  class="ma-0"
                  :style="
                    $vuetify.breakpoint.mdAndDown
                      ? 'font-size:15px; word-break: break-word'
                      : 'font-size:17px  word-break: break-word'
                  "
                >
                  CREDENCIAL VIRTUAL
                </p>
                <!-- Botón de descarga -->
                <!-- <v-btn class="ml-3 brown lighten-5" fab small :elevation="0">
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn> -->
              </v-card-title>
              <v-card-text
                class="wDarkerBlue--text mt-5"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'font-size:17px'
                    : 'font-size:15px'
                "
              >
                <v-row
                  class="py-4 mx-0 d-flex"
                  :class="
                    $vuetify.breakpoint.smAndDown
                      ? 'flex-column align-center'
                      : 'justify-space-between align-center'
                  "
                >
                  <v-avatar
                    color="#1c2434"
                    size="120"
                    :class="$vuetify.breakpoint.smAndUp || 'mb-6'"
                  >
                    <v-img
                      v-if="ppicture && ppicture !== 'NOT_ASSIGNED'"
                      :src="ppicture"
                    ></v-img>
                    <v-img
                      v-else
                      src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0="
                    ></v-img>
                  </v-avatar>
                  <v-btn
                    class="btnstyle subtitle-2 font-weight-black pa-6 d-flex justify-center primary-variant"
                    :loading="load"
                    :color="homologateStatusWithColor(status).color"
                  >
                    {{ homologateStatusWithColor(status).status }}
                  </v-btn>
                </v-row>
                <v-row>
                  <div
                    v-for="(item, i) in filtItems"
                    :key="i"
                    class="my-2 mx-4 text-left body-2"
                  >
                    <span
                      class="primary-variant subtitle-2 font-weight-black mayus"
                    >
                      {{ item.title }}
                    </span>
                    - {{ item.content }}
                  </div>
                </v-row>
              </v-card-text>

              <v-card-actions
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-end'
                "
                class="d-flex"
              >
              </v-card-actions>
            </v-card>
          </v-img>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-0">
        <v-card
          height="100%"
          elevation="2"
          class="pt2 d-flex justify-center align-center pa-4"
          :rounded="$vuetify.breakpoint.mdAndDown ? 'b-xl t-0' : 'r-xl l-0'"
        >
          <v-img
            :max-height="$vuetify.breakpoint.smAndDown ? 160 : 300"
            :max-width="$vuetify.breakpoint.smAndDown ? 160 : 300"
            class="rounded-0"
            :src="id"
            content-class="filter-red"
          />

          <!--Overlay for expired-->
          <v-overlay
            absolute
            :value="true"
            :color="status === 'EXPIRED' ? 'grey lighten-4' : 'red lighten-4'"
            opacity="0.95"
            v-if="status === 'EXPIRED' || status === 'INACTIVO'"
          >
            <v-row justify="center">
              <v-icon color="black"> mdi-message-alert-outline </v-icon>
            </v-row>
            <v-row class="my-4" justify="center">
              <v-col cols="9" class="text-start">
                <span
                  class="black--text subtitle-1 font-weight-black"
                  v-if="status === 'EXPIRED'"
                >
                  Tú membresía ha expirado
                </span>
                <span
                  class="black--text subtitle-1 font-weight-black"
                  v-if="status === 'INACTIVO'"
                >
                  Tú membresía está inactiva
                </span>
                <br />
                <span class="black--text subtitle-1">
                  {{
                    user.role == "MEMBER"
                      ? "Renueva tu membresía para seguir disfrutando de los beneficios"
                      : "Por favor comunicate con el propietario de la cuenta principal."
                  }}
                </span>
              </v-col>
            </v-row>
          </v-overlay>

          <!--Overlay for prospecto-->
          <v-overlay
            absolute
            :value="true"
            color="yellow lighten-4"
            opacity="0.95"
            v-if="status === 'PROSPECTO'"
          >
            <v-row justify="center">
              <v-icon color="#1B273E"> mdi-message-alert-outline </v-icon>
            </v-row>
            <v-row class="my-4" justify="center">
              <v-col cols="9" class="text-start">
                <span
                  class="primary-variant subtitle-1 font-weight-black"
                  v-if="nationalIsPaid"
                >
                  Perfecto, solo nos falta el pago local
                </span>
                <span
                  class="primary-variant subtitle-1 font-weight-black"
                  v-else
                >
                  1.
                  <span class="font-weight-regular">
                    Primero debes cubrir tu
                  </span>
                  pago nacional <br />
                  2.
                  <span class="font-weight-regular">
                    Una vez confirmado tu pago, podrás continuar con el
                  </span>
                  pago local <br />
                  3.
                  <span class="font-weight-regular">
                    Una vez cubiertos ambos pagos, se habilitará tu tablero.
                  </span>
                </span>
                <br />
                <span class="primary-variant subtitle-1">
                  {{
                    user.role == "MEMBER"
                      ? nationalIsPaid
                        ? "Continua con el pago para disfrutar de todos los beneficios."
                        : ""
                      : "Tienes pagos pendientes, por favor comunicate con el propietario de la cuenta principal."
                  }}
                </span>
                <v-row justify="end" class="pt-8" v-if="user.role == 'MEMBER'">
                  <v-btn
                    v-if="nationalIsPaid"
                    color="#1B273E"
                    class="caption"
                    rounded
                    :to="`/member/payment/${user.affiliation.local._id}`"
                  >
                    Iniciar pago
                    <v-icon small class="ml-2">mdi-arrow-expand-right</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      v-if="
        (ableToRenewNational || ableToRenewLocal || status != 'ACTIVE') &&
        user.role == 'MEMBER'
      "
    >
      <v-col cols="11">
        <v-row class="mx-0" justify="end" align="center">
          <v-icon class="mr-1" color="#1B273E">
            mdi-message-alert-outline
          </v-icon>
          <span style="color: #1b273e" class="body-2 font-weight-black">
            {{
              status == "EXPIRED"
                ? "Tu membresía ha expirado"
                : status == "INACTIVO"
                ? "Tu membresía está inactiva"
                : ableToRenewNational
                ? "Tu membresía está próxima a vencer"
                : ""
            }}
          </span>
          <v-btn
            color="#1B273E"
            class="mx-2 white--text font-weight-bold"
            rounded
            :to="`/member/renew/2/${user._id}`"
          >
            Renovar Ahora
            <v-icon small class="ml-2">mdi-arrow-expand-right</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  homologateStatusWithColor,
  isActivePaymentNationalH,
  isActivePaymentLocalH,
  ableToRenewH,
} from "@/common/hook/useHelper.js";

export default {
  data() {
    return {
      isActivePaymentNational: false,
      isActivePaymentLocal: false,
      ableToRenewNational: false,
      ableToRenewLocal: false,
      affiliation: {},
      id: "",
      ppicture: "",
      load: false,
      profile: "",
      cred: {},
      items: [
        {
          title: "asociado ",
          content: "",
        },
        { title: "titular ", content: "" },
        { title: "domicilio ", content: "" },
        { title: "capitulo ", content: "" },
        { title: "Municipio ", content: "" },
        { title: "Tipo de membresía: ", content: "" },
        { title: "Nivel de membresía: ", content: "" },
      ],
    };
  },
  methods: {
    ...mapActions("member", ["fetchMemberCredentials"]),
    ...mapActions("membershipUsers", ["fetchMembershipCredentials"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    homologateStatusWithColor,
    isActivePaymentNationalH,
    isActivePaymentLocalH,
    ableToRenewH,
    downloadConstancy() {
      const url = this.user.diplomaUrl;
      window.open(url, "_blank");
    },
    qrLinkId(id) {
      return `${window.location.origin}/member/credential/${id}`;
    },
    updateMemberCredential(credential) {
      this.id = `https://api.qrserver.com/v1/create-qr-code/?data=${this.qrLinkId(
        credential.id
      )}&bgcolor=deeae0`;
      this.items[0].content = credential.associate || "N/D";
      this.items[1].content = credential.name;
      this.items[2].content = credential.address;
      this.items[3].content = credential.branch;
      this.items[4].content = credential.city || "";
      let membershipType = credential.externalId
        ? credential.externalId.substring(0, 2)
        : this.user.role
        ? this.user.role
        : "";
      if (this.user.memberInformation.type) {
        this.items[5].content = this.user.memberInformation.type;
      } else {
        if (membershipType == "AS" || membershipType == "MEMBER") {
          this.items[5].content = "Asociado";
        } else if (
          membershipType == "AF" ||
          membershipType == "MEMBERSHIP_USER"
        ) {
          this.items[5].content = "Afiliado";
        } else if (membershipType == "IN") {
          this.items[5].content = "Internacional";
        } else {
          this.items[5].content = "Prospecto";
        }
      }
      this.items[6].content = this.user.hasOwnProperty("memberOf")
        ? this.user.memberOf.affiliationType
        : "N/D";
      this.ppicture =
        this.user.role == "MEMBER"
          ? credential.branchProfilePicture
          : credential.profilePicture;
      this.profile =
        credential.name.split(" ")[0].split("")[0] +
        credential.name.split(" ")[2].split("")[0];
    },
  },

  computed: {
    ...mapState("member", ["credential"]),
    ...mapState("user", ["user"]),
    filtItems() {
      //devuelve los items pero si el valor es "" devuelve ND
      return this.items.map((item) => {
        if (item.content == "") {
          item.content = "ND";
        }
        return item;
      });
    },
    nationalIsPaid() {
      return (
        this.user.affiliation.national &&
        this.user.affiliation.national.details &&
        this.user.affiliation.national.details.status === "ACTIVE"
      );
    },
    status() {
      return this.user.validationStatus;
    },
  },
  watch: {
    credential(newCredential) {
      this.updateMemberCredential(newCredential.credentialInformation);
    },
    user(val) {
      this.affiliation = this.user.affiliation;
    },
    affiliation(val) {
      if (val) {
        this.isActivePaymentNational = this.isActivePaymentNationalH(val);
        this.isActivePaymentLocal = this.isActivePaymentLocalH(val);

        if (val.national.details) {
          let status = val.national.details.status;
          let affiliateInformation = val.national.details.affiliateInformation;

          this.ableToRenewNational = this.ableToRenewH(
            affiliateInformation,
            status
          );
        }
        if (val.local.details) {
          let status = val.local.details.status;
          let affiliateInformation = val.local.details.affiliateInformation;

          this.ableToRenewLocal = this.ableToRenewH(
            affiliateInformation,
            status
          );
        }
      }
    },
  },
  async mounted() {
    this.loading();
    this.affiliation = this.user.affiliation;
    this.load = true;
    if (!this.credential.credentialInformation) {
      if (this.user.role === "MEMBER") {
        this.cred = (await this.fetchMemberCredentials()).credential;
      } else {
        this.cred = (await this.fetchMembershipCredentials()).credential;
      }
    } else {
      this.cred = this.credential.credentialInformation;
    }
    try {
      this.updateMemberCredential(this.cred);
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
      this.load = false;
    }
  },
};
</script>

<style scoped>
.v-card__title {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 17px;
}

.pt2 {
  background-color: #deeae0;
}

.btnstyle {
  border-radius: 15px;
  font-weight: 900;
}

.mayus {
  text-transform: uppercase;
}

.head6 {
  font-family: Montserrat Bold;
}
</style>