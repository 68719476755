<template>
  <div>
    <MemberHomeNew></MemberHomeNew>
  </div>
</template>

<script>
import MemberHome from './memberHome.vue'
import MemberHomeNew from './memberHomeNew.vue'
import {mapState} from 'vuex'
export default {
  name: 'memberHome',
  components: {
    MemberHome,
    MemberHomeNew
  },
  computed: {
    ...mapState('user', ['user']),
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = '62b622c66a7441ae20159d7d'
      const ampi2 = '6297b0fec7bf8b6f826313be'
      return this.user.headOffice === ampi1 || this.user.headOffice === ampi2
    },
  },
  mounted() {
    console.log(this.user.role);
    this.$gtag.screenview({
        app_name: 'App',
        screen_name: `${this.user.role} Landing Page`,
      })  
  },
}
</script>

<style>

</style>